import { useReducer, createContext, ReactNode, useEffect, useState } from 'react';
import { Alert } from 'rsuite';
import { PropertyApi } from 'services/api/property';
import { cloneDeep } from 'lodash';
import { useUser } from 'contexts/UserContext';
import { format } from 'date-fns';
import { useErrorTracker } from 'utils/use-error-tracker';
import { PropertiesAction, PropertiesState } from 'src/types/Properties/PropertiesContext';
import { getDateWithin7Days } from 'utils/getDateWithin7Days';
import { Property, Room, Tenant } from 'src/types/Properties/Properties';
import { User } from 'src/types/User/user';

const initialState: PropertiesState = {
  loading: true,
  error: '',
  data: [],
  idToBuildingInfoMap: {},
};

const getIdToBuildingInfoMap = (buildingArray: Property[]) => {
  const idToBuildingInfo: Record<string, Property> = {};
  if (buildingArray) {
    buildingArray.forEach(b => {
      idToBuildingInfo[b.id] = b;
    });
  }

  return idToBuildingInfo;
};

const reducer = (propertiesState: PropertiesState, action: PropertiesAction): PropertiesState => {
  const propertiesStateCopy = cloneDeep(propertiesState);
  switch (action.type) {
    case 'FETCH_SUCCESS': {
      return {
        loading: false,
        error: '',
        data: action.payload,
        idToBuildingInfoMap: getIdToBuildingInfoMap(action.payload),
      };
    }
    case 'FETCH_FAILURE': {
      return {
        loading: false,
        error: 'There was an error fetching data.',
        data: [],
        idToBuildingInfoMap: {},
      };
    }
    case 'CLEAR_DATA': {
      return initialState;
    }
    case 'ADD_PROPERTY': {
      const newProperties = [...propertiesState.data, { ...action.payload }];
      propertiesStateCopy.data = newProperties;
      propertiesStateCopy.idToBuildingInfoMap = getIdToBuildingInfoMap(propertiesStateCopy.data);
      return propertiesStateCopy;
    }
    case 'UPDATE_PROPERTY': {
      const indexToEdit = propertiesState.data.findIndex(prop => prop.id === action.payload.id);
      propertiesStateCopy.data[indexToEdit] = action.payload;
      return propertiesStateCopy;
    }
    case 'ADD_TENANT': {
      const propToEdit = propertiesState.data.findIndex(prop => prop.id === action.payload.property.id);
      const rmToEdit = propertiesState.data[propToEdit].rooms.findIndex(
        (room: Room) => room.id === action.payload.room.id
      );
      const newTenant: Tenant = {
        ...action.payload.tenant,
        name: `${action.payload.tenant.firstName} ${action.payload.tenant.lastName}`,
        moveIn: format(new Date(action.payload.tenant.moveIn), 'yyyy-MM-dd'),
        moveOut: format(new Date(action.payload.tenant.moveOut), 'yyyy-MM-dd'),
        movingInWithin7Days: getDateWithin7Days(action.payload.tenant.moveIn),
        movingOutWithin7Days: getDateWithin7Days(action.payload.tenant.moveOut),
      };
      if (rmToEdit !== -1) {
        propertiesStateCopy.data[propToEdit].rooms[rmToEdit].tenants.push(newTenant);
      } else {
        propertiesStateCopy.data[propToEdit].rooms.push({ ...action.payload.room, tenants: [newTenant] });
      }
      propertiesStateCopy.idToBuildingInfoMap = getIdToBuildingInfoMap(propertiesStateCopy.data);
      return propertiesStateCopy;
    }
    case 'UPDATE_TENANT': {
      const propertyToEdit = propertiesState.data.findIndex(prop => prop.id === action.payload.property.id);
      const roomToEdit = propertiesState.data[propertyToEdit].rooms.findIndex(
        (room: Room) => room.id === action.payload.tenant.room?.id // Ensure room exists
      );
      const tenantToEdit = propertiesState.data[propertyToEdit].rooms[roomToEdit].tenants.findIndex(
        (tenant: Tenant) => tenant.id === action.payload.tenant.id
      );
      const updatedTenant: Tenant = {
        ...action.payload.tenant,
        name: `${action.payload.tenant.firstName} ${action.payload.tenant.lastName}`,
        movingInWithin7Days: getDateWithin7Days(action.payload.tenant.moveIn),
        movingOutWithin7Days: getDateWithin7Days(action.payload.tenant.moveOut),
      };
      propertiesStateCopy.data[propertyToEdit].rooms[roomToEdit].tenants[tenantToEdit] = updatedTenant;
      propertiesStateCopy.idToBuildingInfoMap = getIdToBuildingInfoMap(propertiesStateCopy.data);
      return propertiesStateCopy;
    }
    case 'ARCHIVE_PROPERTY': {
      return { ...propertiesState, data: propertiesState.data.filter(p => p.id !== action.payload) };
    }
    case 'UPDATE_PRICING_SETTINGS': {
      const updatedProperties = propertiesState.data.map(property => {
        if (property.buildingId === action.payload.buildingId) {
          return { ...property, pricingSetting: action.payload };
        }
        return property;
      });
      return { ...propertiesState, data: updatedProperties };
    }
    case 'UPDATE_RENEWALS': {
      const propertiesWithUpdatedRenewals = propertiesState.data.map(property => {
        const updatedRenewals = action.payload.filter(renewal => property.id === renewal.propertyId);
        return { ...property, renewals: updatedRenewals };
      });
      return { ...propertiesState, data: propertiesWithUpdatedRenewals };
    }
    default:
      return propertiesState;
  }
};

export const PropertiesContext = createContext<{
  state: PropertiesState;
  dispatch: React.Dispatch<PropertiesAction>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const PropertiesContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const errorTracker = useErrorTracker();
  const [propertiesState, propertiesDispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState<User | undefined>(undefined);
  const getUser = useUser();

  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      user !== getUser.user && setUser(getUser.user);
    }
  }, [user, getUser]);

  useEffect(() => {
    if (user) {
      propertiesDispatch({ type: 'CLEAR_DATA' });
      PropertyApi.getProperties()
        .then(res => {
          propertiesDispatch({ type: 'FETCH_SUCCESS', payload: res.data });
        })
        .catch(err => {
          propertiesDispatch({ type: 'FETCH_FAILURE' });
          errorTracker.error('Get properties (properties context) error');
          Alert.error('Something went wrong while fetching properties.', 5000);
          console.error(err);
        });
    }
  }, [errorTracker, user]);

  return (
    <PropertiesContext.Provider value={{ state: propertiesState, dispatch: propertiesDispatch }}>
      {children}
    </PropertiesContext.Provider>
  );
};
