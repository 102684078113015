import React, { createContext, useReducer, useEffect, useState } from 'react';
import { useUser } from 'contexts/UserContext.tsx';

const initialState = {
  loading: true,
  error: '',
  data: [],
  refreshTimestamp: new Date().getTime(),
};

const reducer = (buildingState, action) => {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return { ...buildingState, loading: false, error: '', data: action.payload };
    case 'FETCH_FAILURE':
      return { ...buildingState, loading: false, error: 'There was an error fetching data.', data: {} };
    case 'REFRESH_DATA':
      return { ...buildingState, loading: true, refreshTimestamp: new Date().getTime() };
    case 'CLEAR_DATA':
      return initialState;
    default:
      return buildingState;
  }
};

export const BuildingContext = createContext(initialState);

export const BuildingContextProvider = ({ children }) => {
  const [buildingState, buildingDispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState(undefined);
  const getUser = useUser();

  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      // console.log("found user", getUser.user.userType);
      user !== getUser && setUser(getUser.user);
    }
  }, [user, getUser]);

  useEffect(() => {
    if (user) {
      buildingDispatch({ type: 'CLEAR_DATA' });
      buildingDispatch({ ...buildingState, loading: true });
    }
  }, [user, buildingState, buildingState.refreshTimestamp]);

  return (
    <BuildingContext.Provider value={{ state: buildingState, dispatch: buildingDispatch }}>
      {children}
    </BuildingContext.Provider>
  );
};
