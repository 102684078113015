import http from '../http';

/**
 * Ticket Api Service
 */
export class TicketApiService {
  urlPrefix = 'api/tickets';

  getTickets() {
    return http.get(`${this.urlPrefix}`);
  }

  createRequest(form) {
    return http.post(`${this.urlPrefix}/create-request`, form);
  }

  createRequestLandlord(data) {
    return http.post(`${this.urlPrefix}/create-request/landlord`, data);
  }

  getRequests(data) {
    return http.get(`${this.urlPrefix}/fetch-requests`, { params: data });
  }

  createMessage(form) {
    return http.post(`${this.urlPrefix}/create-message`, form);
  }

  getMessages(data) {
    return http.get(`${this.urlPrefix}/get-messages`, data);
  }

  getTicketById(ticketId) {
    return http.get(`${this.urlPrefix}/${ticketId}`);
  }

  getPaymentMethods() {
    return http.get(`${this.urlPrefix}/payment-methods`);
  }

  updatePaymentMethod(paymentMethodId) {
    return http.post(`${this.urlPrefix}/payment-methods`, paymentMethodId);
  }

  getTicketPaymentIntent(ticketId) {
    return http.get(`${this.urlPrefix}/${ticketId}/payment-intent`);
  }

  updateRequest(ticketId, data) {
    return http.put(`${this.urlPrefix}/update-request?ticketId=${ticketId}`, data);
  }

  updateZendeskTicket(ticketId, data) {
    return http.put(`${this.urlPrefix}/update-zendesk-ticket?ticketId=${ticketId}`, data);
  }

  assignTicket(ticketId, data) {
    return http.put(`${this.urlPrefix}/${ticketId}/assign`, data);
  }

  getReservedFundsPaymentIntent(amount, captureMethod) {
    return http.post(`${this.urlPrefix}/payment-intent`, { amount, ...(captureMethod && { captureMethod }) });
  }

  getCapturePayment(paymentIntentId, amount) {
    return http.post(`${this.urlPrefix}/payment-intent/${paymentIntentId}`, { amount });
  }

  createStripeCustomer() {
    return http.post(`${this.urlPrefix}/create-stripe-customer`);
  }

  createStripeSubscription(priceId) {
    return http.post(`${this.urlPrefix}/create-stripe-subscription`, { priceId });
  }

  approveTicket(ticketId, data) {
    return http.post(`${this.urlPrefix}/${ticketId}/approve`, data);
  }

  raiseConcernTicket(ticketId, data) {
    return http.post(`${this.urlPrefix}/${ticketId}/raise-concern`, data);
  }

  sendToSlack(data) {
    return http.post(`${this.urlPrefix}/slack-support`, data);
  }

  uploadSlackImage(file) {
    const form = new FormData();
    form.append('image', file);

    return http.post(`${this.urlPrefix}/slack-support/upload`, form);
  }
}

export const TicketApi = new TicketApiService();
