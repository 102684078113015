import React, { createContext, useReducer, useEffect, useState } from 'react';
import { BookingApi } from 'services/api/booking';
import { cloneDeep } from 'lodash';
import { useUser } from 'contexts/UserContext.tsx';
import { Alert } from 'rsuite';
import { useErrorTracker } from 'utils/use-error-tracker';

const initialState = {
  loading: true,
  error: '',
  data: [],
};

const reducer = (activitiesState, action) => {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return {
        loading: false,
        error: '',
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        loading: false,
        error: 'There was an error fetching activities data.',
        data: [],
      };
    case 'CLEAR_DATA':
      return initialState;
    case 'UPDATE': {
      const newActivitiesState = cloneDeep(activitiesState);
      const idToEdit = newActivitiesState.data.findIndex(activity => activity.id === action.payload.id);
      newActivitiesState.data[idToEdit] = {
        ...action.payload,
        bookingId: action.payload.booking.id,
        userFirstName: action.payload.booking.userFirstName,
      };
      return {
        loading: false,
        error: '',
        data: newActivitiesState.data,
      };
    }
    case 'CREATE': {
      const currentActivitiesState = cloneDeep(activitiesState);
      currentActivitiesState.data.push({
        ...action.payload,
        bookingId: action.payload.booking.id,
        userFirstName: action.payload.booking.userFirstName,
      });
      return {
        loading: false,
        error: '',
        data: currentActivitiesState.data,
      };
    }
    default:
      return activitiesState;
  }
};

export const ActivitiesContext = createContext(initialState);

export const ActivitiesContextProvider = ({ children }) => {
  const errorTracker = useErrorTracker();
  const [activitiesState, activitiesDispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState(undefined);
  const getUser = useUser();

  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      user !== getUser && setUser(getUser.user);
    }
  }, [user, getUser]);
  useEffect(() => {
    if (user) {
      activitiesDispatch({ type: 'CLEAR_DATA' });
      BookingApi.fetchAllActivities()
        .then(res => {
          activitiesDispatch({ type: 'FETCH_SUCCESS', payload: res.data });
        })
        .catch(err => {
          activitiesDispatch({ type: 'FETCH_FAILURE' });
          errorTracker.error('Fetch all activities (activity context) error');
          Alert.error(`Something went wrong while fetching activities.`, 5000);
          console.error(err);
        });
    }
  }, [errorTracker, user]);

  return (
    <ActivitiesContext.Provider value={{ state: activitiesState, dispatch: activitiesDispatch }}>
      {children}
    </ActivitiesContext.Provider>
  );
};
