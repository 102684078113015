import React, { useContext, useState, useEffect, createContext, useCallback } from 'react';
import http from '../services/http';
import { useSetUserInfoOnErrorTracker, useErrorTracker } from '../utils/use-error-tracker';
import { User } from '../types/User/user';

interface MutateUserResponse {
  data: User;
}

// Define the User context type
interface UserContextType {
  user: User | null;
  userDataContextBeingInitialized: boolean;
  mutateUser: (responseData: MutateUserResponse) => void;
  refreshUser: () => Promise<User | null>;
  clearUser: () => void;
  isUserLoggedIn: () => boolean;
}

// Function to fetch the user data
const fetchUser = async (): Promise<User | null> => {
  try {
    const res = await fetch('/api/users/me');
    if (res.status === 200) {
      return res.json();
    }
    return null; // There is probably an error fetching the user.
  } catch (err) {
    console.error(err);
    return null;
  }
};

// Create the User context with an initial value of undefined
export const UserDataContext = createContext<UserContextType | null>(null);

// Define the UserDataProvider component
export const UserContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const errorTracker = useErrorTracker();
  const [user, setUser] = useState<User | null>(null);
  const [userDataContextBeingInitialized, setUserDataContextBeingInitialized] = useState(true);
  useSetUserInfoOnErrorTracker(user);

  const mutateUser = useCallback((responseData: MutateUserResponse) => {
    setUser(responseData?.data);
  }, []);

  const refreshUser = useCallback(async () => {
    const user = await fetchUser();
    setUser(user);
    setUserDataContextBeingInitialized(false);
    return user;
  }, []);

  const clearUser = useCallback(() => {
    setUser(null);
  }, []);

  const isUserLoggedIn = useCallback(() => {
    return !!user;
  }, [user]);

  useEffect(() => {
    refreshUser();

    const responseInterceptor = http.interceptors.response.use(
      response => response,
      error => {
        if (error?.response?.status === 401) {
          clearUser();
        } else if (error?.response?.status >= 500) {
          errorTracker.critical('5XX HTTP Error was intercepted. URL=' + error?.config?.url, error, error?.response);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      http.interceptors.response.eject(responseInterceptor);
    };
  }, [clearUser, errorTracker, refreshUser]);

  return (
    <UserDataContext.Provider
      value={{ userDataContextBeingInitialized, user, mutateUser, refreshUser, clearUser, isUserLoggedIn }}>
      {children}
    </UserDataContext.Provider>
  );
};

// Custom hook to use the User context
export function useUser(): UserContextType {
  const userDataContext = useContext(UserDataContext);
  if (!userDataContext) {
    throw new Error('useUser must be used within a UserDataProvider');
  }

  useEffect(() => {
    const user = userDataContext.user;
    if (user && user.userType === 'landlord') {
      // @ts-expect-error: Intercom is not typed, using it to handle support tickets
      window.Intercom('boot', {
        app_id: 'jxutt9op',
        user_id: user.id,
        name: user.firstName,
        email: user.email,
        company: user.organisationId,
      });
    } else {
      // @ts-expect-error: Intercom is not typed, using it to handle support tickets
      window.Intercom('shutdown');
    }
  }, [userDataContext.user]);

  return userDataContext;
}
