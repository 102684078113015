/**
 * Axios Request Wrapper
 */
import axios from 'axios';

/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: '/',
  withCredentials: true,
});

client.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (502 === error.response.status) {
      //Alert.error("Something went wrong on our side. Please try again!", 5000);
    }
    return Promise.reject(error);
  }
);

export default client;
