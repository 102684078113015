import React, { createContext, useReducer, useEffect, useState } from 'react';
import { TicketApi } from 'services/api/ticket';
import { useUser } from 'contexts/UserContext.tsx';

const initialState = {
  loading: true,
  error: '',
  data: [],
  refreshTimestamp: new Date().getTime(),
};

const reducer = (ticketState, action) => {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return { ...ticketState, loading: false, error: '', data: action.payload };
    case 'FETCH_FAILURE':
      return { ...ticketState, loading: false, error: 'There was an error fetching data.', data: {} };
    case 'REFRESH_DATA':
      return { ...ticketState, loading: true, refreshTimestamp: new Date().getTime() };
    case 'CLEAR_DATA':
      return initialState;
    default:
      return ticketState;
  }
};

export const TicketContext = createContext(initialState);

export const TicketContextProvider = ({ children }) => {
  const [ticketState, ticketDispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState(undefined);
  const getUser = useUser();

  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      // console.log("found user", getUser.user.userType);
      user !== getUser && setUser(getUser.user);
    }
  }, [getUser, user]);

  useEffect(() => {
    if (user) {
      ticketDispatch({ type: 'CLEAR_DATA' });
      ticketDispatch({ type: 'FETCH_INIT' });
      TicketApi.getTickets()
        .then(res => {
          ticketDispatch({ type: 'FETCH_SUCCESS', payload: res.data });
        })
        .catch(err => {
          ticketDispatch({ type: 'FETCH_FAILURE', error: err });
        });
    }
  }, [user, ticketDispatch]);

  return (
    <TicketContext.Provider value={{ state: ticketState, dispatch: ticketDispatch }}>{children}</TicketContext.Provider>
  );
};
