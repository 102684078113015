export enum AnalyticsDashboardCategory {
  MARKETING_ACTIVITY = 'Marketing Activity',
  OCCUPANCY_AND_LEASING = 'Occupancy and Leasing',
  EXECUTIVE_SUMMARY = 'Executive Summary',
  OPERATIONS = 'Operations',
  FINANCIAL_PERFORMANCE = 'Financial Performance',
  REVENUE_MANAGEMENT = 'Revenue Management',
  MANAGEMENT_DATA = 'Management Data',
  UNIT_TURN_DATA = 'Unit Turn Data',
  MARKETING_AND_LEASING_DATA = 'Marketing and Leasing Data',
  BUDGETED_VS_ACTUAL = 'Budgeted vs. Actual',
  ASSET_MANAGEMENT = 'Asset Management',
  WORK_ORDERS = 'Work Orders',
}

export type Insight = {
  id: number;
  insightSummary: string;
  insightName: string;
  insightText: React.ReactNode;
  monitoring: boolean;
  dashboardCategory: AnalyticsDashboardCategory;
  note?: string;
};

export type MonitoringInsight = {
  id: string;
  update: React.ReactNode;
  date: string;
  time: string;
};

export const monitoringInsightData: MonitoringInsight[] = [
  {
    id: 'm1',
    update: (
      <span>
        Lease velocity is reduced by <strong>16%</strong> post accepting performance insight last quarter.
      </span>
    ),
    date: 'Aug. 12th, 2024',
    time: '2:37PM',
  },
  {
    id: 'm2',
    update: (
      <span>
        Actions on market pricing resulted in over <strong>4%</strong> improvement in effective net rents across the{' '}
        <strong>Boston</strong> portfolio.
      </span>
    ),
    date: 'Aug. 4th, 2024',
    time: '8:19AM',
  },
  {
    id: 'm3',
    update: (
      <span>
        Changing the landing page and completing the property profile for <strong>Liberty Towers</strong> has resulted
        in a <strong>24%</strong> increase in web leads for that property.
      </span>
    ),
    date: 'Aug. 6th, 2024',
    time: '7:12PM',
  },
  {
    id: 'm4',
    update: (
      <span>
        You are getting <strong>14%</strong> more leads on Direct to Website across <strong>Toronto</strong> with an
        average <strong>9.96%</strong> conversion rate. The change to allocate higher percentage of marketing budget to
        website has saved you <strong>$459</strong> this quarter.
      </span>
    ),
    date: 'Jun. 22nd, 2024',
    time: '11:39AM',
  },
  {
    id: 'm5',
    update: (
      <span>
        NOI Improved in <strong>Liberty Towers</strong> by <strong>4.7%</strong> after implementing marketing cost
        change actions.
      </span>
    ),
    date: 'Jul. 15th, 2024',
    time: '12:57PM',
  },
  {
    id: 'm6',
    update: (
      <span>
        Lease velocity is reduced by <strong>16%</strong> post accepting performance insight last quarter.
      </span>
    ),
    date: 'Jul. 9th, 2024',
    time: '1:05PM',
  },
];

export const insightData: Insight[] = [
  // {
  //   id: 1,
  //   dashboardCategory: AnalyticsDashboardCategory.MARKETING_ACTIVITY,
  //   insightName: 'Seasonal trend identified',
  //   insightSummary: 'Reallocating budgets to Facebook will optimize spending.',
  //   insightText: (
  //     <span>
  //       <strong>0%</strong> conversion through Zumper and an avg. <strong>8%</strong> conversion on Facebook over the
  //       past 5 weeks.
  //     </span>
  //   ),
  //   monitoring: false,
  // },
  // {
  //   id: 2,
  //   dashboardCategory: AnalyticsDashboardCategory.MARKETING_ACTIVITY,
  //   insightName: 'Performance Insight',
  //   insightSummary: 'Reducing marketing spending on selected portfolios by 30% could save budgets.',
  //   insightText: (
  //     <span>
  //       Upcoming vacancies in the coming month across the <span>Toronto</span> portfolio have reduced by{' '}
  //       <strong>57%</strong> compared to the last period. The overall vacancy rate in <strong>Toronto</strong> Portfolio
  //       is <strong>1.10%</strong>
  //     </span>
  //   ),
  //   monitoring: false,
  //   note: 'Est. Savings $1155',
  // },
  // {
  //   id: 3,
  //   dashboardCategory: AnalyticsDashboardCategory.MARKETING_ACTIVITY,
  //   insightName: 'Performance Insight',
  //   insightSummary: 'Reduce marketing spend by 10% in Boston.',
  //   insightText: (
  //     <span>
  //       The vacancy rate is expected to go down by another <strong>23%</strong>
  //     </span>
  //   ),
  //   monitoring: true,
  //   note: 'Est. Savings $400',
  // },
  // {
  //   id: 4,
  //   dashboardCategory: AnalyticsDashboardCategory.OCCUPANCY_AND_LEASING,
  //   insightName: 'Performance Insight',
  //   insightSummary: 'Focus specifically on 1 bedroom inquiries to boost conversions.',
  //   insightText: (
  //     <span>
  //       The conversion rate on the leads has reduced by <strong>5%</strong> due to an average <strong>18%</strong>{' '}
  //       reduction in available units in <strong>Bonston</strong>.<strong>85%</strong> of current and upcoming units are
  //       1-bed units, while <strong>55%</strong> of leasing activities in the current period are done on 2-bedroom
  //       prospects for the portfolio.
  //     </span>
  //   ),
  //   monitoring: true,
  // },
  // {
  //   id: 5,
  //   dashboardCategory: AnalyticsDashboardCategory.OCCUPANCY_AND_LEASING,
  //   insightName: 'Revenue Improvement Opportunity',
  //   insightSummary: 'Good time to increase rental pricing. This can improve effective rents.',
  //   insightText: (
  //     <span>
  //       Your marketed rent is <span>10%</span> below the recent average in <strong>Toronto</strong>. Your portfolio
  //       shows a <strong>23%</strong> renewal growth and an average <strong>2.10%</strong> conversion rate.
  //     </span>
  //   ),
  //   monitoring: false,
  //   note: 'Est. Rev. Increase: $17,784',
  // },
  // {
  //   id: 6,
  //   dashboardCategory: AnalyticsDashboardCategory.OCCUPANCY_AND_LEASING,
  //   insightName: 'Performance Insight',
  //   insightSummary: 'Reduce No shows by setting up pre-showing follow-ups.',
  //   insightText: (
  //     <span>
  //       <strong>55%</strong> more people showed up to the showings in <strong>Boston</strong> with activities one day
  //       before the showing day. You have an average of <strong>12%</strong> no-shows across <strong>Boston</strong>.
  //     </span>
  //   ),
  //   monitoring: false,
  // },
  // {
  //   id: 7,
  //   dashboardCategory: AnalyticsDashboardCategory.OCCUPANCY_AND_LEASING,
  //   insightName: 'Performance Insight',
  //   insightSummary: 'Optimise conversion with shared lead distribution between Troy Boston and Parkway Apartments.',
  //   insightText: (
  //     <span>
  //       <strong>Troy Boston</strong> has a conversion rate of <strong>3.30%</strong> and{' '}
  //       <strong>Parkway Apartments</strong> has a conversion rate of <strong>2.04%</strong>.{' '}
  //       <strong>Parkway Apartments</strong> gets <strong>33%</strong>
  //       more leads resulting in a mismatch with availability in <strong>Parkway Apartments</strong>.
  //     </span>
  //   ),
  //   monitoring: true,
  // },
  {
    id: 1,
    dashboardCategory: AnalyticsDashboardCategory.WORK_ORDERS,
    insightName: 'Performance Insight',
    insightSummary: 'Improve the NPS score by acting on the requests faster.',
    insightText: (
      <span>
        There has been an <span className="font-bold">17%</span> increase in pending workorders across{' '}
        <span className="font-bold">Toronto</span>, this corresponds to <span className="font-bold">6.72%</span> decrese
        in NPS in this period.
      </span>
    ),
    monitoring: true,
  },
  {
    id: 2,
    dashboardCategory: AnalyticsDashboardCategory.EXECUTIVE_SUMMARY,
    insightName: 'Performance Insight',
    insightSummary: 'Focus specifically on 1 bedroom inquiries to boost conversions.',
    insightText: (
      <span>
        The conversion rate on the leads has reduced by <span className="font-bold">5%</span> due to an average{' '}
        <span className="font-bold">18%</span> reduction in available units in <span className="font-bold">Boston</span>
        . <span className="font-bold">85%</span> of current and upcoming units are 1-bed units, while{' '}
        <span className="font-bold">55%</span> of leasing activities in the current period are done on 2-bedroom
        prospects for the portfolio.
      </span>
    ),
    monitoring: true,
  },
  {
    id: 3,
    dashboardCategory: AnalyticsDashboardCategory.FINANCIAL_PERFORMANCE,
    insightName: 'Performance Insight',
    insightSummary: 'Increase NOI by reducing Utility costs.',
    insightText: (
      <span>
        Increase NOI by reducing Utility costs in the <span className="font-bold">Toronto</span> portfolio.
      </span>
    ),
    monitoring: true,
  },
  {
    id: 4,
    dashboardCategory: AnalyticsDashboardCategory.FINANCIAL_PERFORMANCE,
    insightName: 'Performance Insight',
    insightSummary: 'Reduce cost overruns by looking into filter replacement issues.',
    insightText: (
      <span>
        <span className="font-bold">40%</span> of the HVAC work orders amounting to{' '}
        <span className="font-bold">$34,432</span> were caused due to &quot;filter not replaced.&quot; This has impacted
        your NOI by <span className="font-bold">3.5%</span> on average compared to last quarter in{' '}
        <span className="font-bold">Boston</span>.
      </span>
    ),
    monitoring: true,
  },
  {
    id: 5,
    dashboardCategory: AnalyticsDashboardCategory.MARKETING_ACTIVITY,
    insightName: 'Performance Insight',
    insightSummary: 'Reallocating budgets to Facebook will optimize spending.',
    insightText: (
      <span>
        <span className="font-bold">0%</span> conversion through Zumper and an avg.{' '}
        <span className="font-bold">8%</span> conversion on Facebook over the past 5 weeks.
      </span>
    ),
    monitoring: true,
  },
];
