import http from '../http';

/**
 * Auth Api Service
 */
export class AuthApiService {
  urlPrefix = 'api/auth';

  login(data) {
    return http.post(`${this.urlPrefix}/login`, data);
  }

  signup(data) {
    return http.post(`${this.urlPrefix}/register`, data);
  }

  generateResetPasswordLink(data) {
    return http.post(`${this.urlPrefix}/reset-password`, data);
  }

  createSubUser(data) {
    return http.post(`${this.urlPrefix}/subuser`, data);
  }

  verifyResetPasswordLink(token) {
    return http.get(`${this.urlPrefix}/reset-password/${token}`);
  }

  getIsVerified(email) {
    return http.post(`${this.urlPrefix}/get-isverified`, email);
  }

  setNewPassword(token, data) {
    return http.post(`${this.urlPrefix}/reset-password/${token}`, data);
  }

  generateVerificationPin(data) {
    return http.post(`${this.urlPrefix}/verify-email`, data);
  }

  verifyVerificationPin(data) {
    return http.post(`${this.urlPrefix}/verify-email/pin`, data);
  }

  updatePassword(data) {
    return http.post(`${this.urlPrefix}/update-password`, data);
  }

  updateEmail(data) {
    return http.post(`${this.urlPrefix}/update-email`, data);
  }

  logout() {
    return http.post(`${this.urlPrefix}/logout`);
  }

  refresh() {
    return http.post(`${this.urlPrefix}/refresh`);
  }

  checkAuthUserExist(data) {
    return http.post(`${this.urlPrefix}/check-auth-user`, data);
  }
}

export const AuthApi = new AuthApiService();
