import React from 'react';
import Image from 'next/image';
import {
  Provider as RollbarProvider,
  ErrorBoundary as RollbarErrorBoundary,
  useRollbarPerson,
  useRollbar,
} from '@rollbar/react';
import getConfig from 'next/config';
import { Alert, Button } from 'rsuite';

const { publicRuntimeConfig } = getConfig();

export function ErrorTrackerProvider({ children }) {
  const rollbarConfig = {
    accessToken: '497f38a3945f410baa32ec38d1ca74e6',
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV === 'production',
    captureIp: 'anonymize',
    code_version: publicRuntimeConfig?.version,
    autoInstrument: {
      network: true,
      log: process.env.NODE_ENV === 'production',
      dom: true,
      navigation: true,
      connectivity: true,
      contentSecurityPolicy: true,
      errorOnContentSecurityPolicy: false,
    },
  };

  function ErrorFallback({ error }) {
    return (
      <div className={`flex flex-col max-w-xl w-full justify-center mx-auto items-center `}>
        <Image
          src="/static/images/Oops! 404 Error with a broken robot-cuate.png"
          className="max-w-xs"
          alt="error image"
          height={300}
          width={300}
        />
        <h1 className="text-xl my-8 font-bold">Something went wrong.</h1>
        <p>{error ? error.toString() : ''}</p>
        <div className="my-8">
          <Button
            appearance="primary"
            onClick={() => {
              window.location.reload(false);
            }}>
            Reload
          </Button>
        </div>
      </div>
    );
  }

  return (
    <RollbarProvider config={rollbarConfig}>
      <RollbarErrorBoundary
        fallbackUI={ErrorFallback}
        errorMessage="Error in React render"
        extra={(error, info) => (info.componentStack.includes('Experimental') ? { experiment: true } : {})}>
        {children}
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
}

export function displayAndLogErrorHandler(
  errorMesageToDisplay,
  errorMessageToLog,
  extraParamsToLog,
  functionToExecuteAfter
) {
  return error => {
    Alert.error(errorMesageToDisplay);
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      useRollbar().error(
        errorMessageToLog ? errorMessageToLog : errorMesageToDisplay,
        error,
        extraParamsToLog ? extraParamsToLog : error
      );
    } catch (err) {
      console.error(err);
      console.error('ROLLBAR ERROR!');
    }
    if (functionToExecuteAfter) {
      functionToExecuteAfter();
    }
  };
}

export function displayAndLogHttpResErrorIfApplicable(
  httpResponse,
  errorMesageToDisplay,
  errorMessageToLog,
  extraParamsToLog,
  functionToExecuteAfter
) {
  if (httpResponse?.data?.error) {
    displayAndLogErrorHandler(
      errorMesageToDisplay,
      errorMessageToLog,
      extraParamsToLog
        ? { httpResponseData: httpResponse?.data, otherExtraParams: extraParamsToLog }
        : { httpResponseData: httpResponse?.data },
      functionToExecuteAfter
    )(httpResponse?.data?.error);
    return true;
  }
  return false;
}

export function useSetUserInfoOnErrorTracker(user) {
  try {
    useRollbarPerson(user);
  } catch (error) {
    console.error('Error setting up user info on error tracker', error);
  }
}

export function useErrorTracker() {
  return useRollbar();
}
