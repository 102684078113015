import React, { createContext, useReducer, useEffect, useState, ReactNode, Dispatch } from 'react';
import { UserApi } from 'services/api/user';
import { useUser } from 'contexts/UserContext.tsx';
import { TableAccessData } from 'src/types/TableAccess/tableAccess';

interface TableAccessState {
  loading: boolean;
  error: string;
  data: TableAccessData | null;
  refreshTimestamp: number;
}

interface FetchSuccessAction {
  type: 'FETCH_SUCCESS';
  payload: TableAccessData;
}

interface FetchFailureAction {
  type: 'FETCH_FAILURE';
  error: string;
}

interface RefreshDataAction {
  type: 'REFRESH_DATA';
}

interface ClearDataAction {
  type: 'CLEAR_DATA';
}

interface FetchInitAction {
  type: 'FETCH_INIT';
}

type TableAccessAction =
  | FetchSuccessAction
  | FetchFailureAction
  | RefreshDataAction
  | ClearDataAction
  | FetchInitAction;

const initialState: TableAccessState = {
  loading: true,
  error: '',
  data: null,
  refreshTimestamp: new Date().getTime(),
};

const reducer = (state: TableAccessState, action: TableAccessAction): TableAccessState => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, error: '', data: action.payload };
    case 'FETCH_FAILURE':
      return { ...state, loading: false, error: action.error, data: null };
    case 'REFRESH_DATA':
      return { ...state, loading: true, refreshTimestamp: new Date().getTime() };
    case 'CLEAR_DATA':
      return initialState;
    default:
      return state;
  }
};

interface TableAccessContextProps {
  state: TableAccessState;
  dispatch: Dispatch<TableAccessAction>;
}

export const TableAccessContext = createContext<TableAccessContextProps | undefined>(undefined);

interface TableAccessContextProviderProps {
  children: ReactNode;
}

export const TableAccessContextProvider = ({ children }: TableAccessContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState<any>(undefined); // Adjust the type based on your user structure
  const getUser = useUser();

  useEffect(() => {
    if (getUser?.user?.userType === 'landlord') {
      if (user !== getUser.user) {
        setUser(getUser.user);
      }
    }
  }, [user, getUser]);

  useEffect(() => {
    if (user) {
      dispatch({ type: 'CLEAR_DATA' });
      dispatch({ type: 'FETCH_INIT' });

      UserApi.getMyTableAccess()
        .then(res => {
          dispatch({ type: 'FETCH_SUCCESS', payload: res.data });
        })
        .catch(() => {
          dispatch({ type: 'FETCH_FAILURE', error: 'There was an error fetching data.' });
        });
    }
  }, [user, state.refreshTimestamp]);

  return <TableAccessContext.Provider value={{ state, dispatch }}>{children}</TableAccessContext.Provider>;
};
